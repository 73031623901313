const countriesCodes = [
  { country: "Afghanistan", code: "93", iso: "AF", flag: true },
  { country: "Albania", code: "355", iso: "AL", flag: true },
  { country: "Algeria", code: "213", iso: "DZ", flag: true },
  { country: "American Samoa", code: "1-684", iso: "AS", flag: true },
  { country: "Andorra", code: "376", iso: "AD", flag: true },
  { country: "Angola", code: "244", iso: "AO", flag: true },
  { country: "Anguilla", code: "1-264", iso: "AI", flag: true },
  { country: "Antarctica", code: "672", iso: "AQ", flag: false },
  { country: "Antigua and Barbuda", code: "1-268", iso: "AG", flag: true },
  { country: "Argentina", code: "54", iso: "AR", flag: true },
  { country: "Armenia", code: "374", iso: "AM", flag: true },
  { country: "Aruba", code: "297", iso: "AW", flag: true },
  { country: "Australia", code: "61", iso: "AU", flag: true },
  { country: "Austria", code: "43", iso: "AT", flag: true },
  { country: "Azerbaijan", code: "994", iso: "AZ", flag: true },
  { country: "Bahamas", code: "1-242", iso: "BS", flag: true },
  { country: "Bahrain", code: "973", iso: "BH", flag: true },
  { country: "Bangladesh", code: "880", iso: "BD", flag: true },
  { country: "Barbados", code: "1-246", iso: "BB", flag: true },
  { country: "Belarus", code: "375", iso: "BY", flag: true },
  { country: "Belgium", code: "32", iso: "BE", flag: true },
  { country: "Belize", code: "501", iso: "BZ", flag: true },
  { country: "Benin", code: "229", iso: "BJ", flag: true },
  { country: "Bermuda", code: "1-441", iso: "BM", flag: true },
  { country: "Bhutan", code: "975", iso: "BT", flag: true },
  { country: "Bolivia", code: "591", iso: "BO", flag: true },
  { country: "Bosnia and Herzegovina", code: "387", iso: "BA", flag: true },
  { country: "Botswana", code: "267", iso: "BW", flag: true },
  { country: "Brazil", code: "55", iso: "BR", flag: true },
  {
    country: "British Indian Ocean Territory",
    code: "246",
    iso: "IO",
    flag: true,
  },
  { country: "British Virgin Islands", code: "1-284", iso: "VG", flag: true },
  { country: "Brunei", code: "673", iso: "BN", flag: true },
  { country: "Bulgaria", code: "359", iso: "BG", flag: true },
  { country: "Burkina Faso", code: "226", iso: "BF", flag: true },
  { country: "Burundi", code: "257", iso: "BI", flag: true },
  { country: "Cambodia", code: "855", iso: "KH", flag: true },
  { country: "Cameroon", code: "237", iso: "CM", flag: true },
  { country: "Canada", code: "1", iso: "CA", flag: true },
  { country: "Cape Verde", code: "238", iso: "CV", flag: true },
  { country: "Cayman Islands", code: "1-345", iso: "KY", flag: true },
  { country: "Central African Republic", code: "236", iso: "CF", flag: true },
  { country: "Chad", code: "235", iso: "TD", flag: true },
  { country: "Chile", code: "56", iso: "CL", flag: true },
  { country: "China", code: "86", iso: "CN", flag: true },
  { country: "Christmas Island", code: "61", iso: "CX", flag: true },
  { country: "Cocos Islands", code: "61", iso: "CC", flag: true },
  { country: "Colombia", code: "57", iso: "CO", flag: true },
  { country: "Comoros", code: "269", iso: "KM", flag: true },
  { country: "Cook Islands", code: "682", iso: "CK", flag: true },
  { country: "Costa Rica", code: "506", iso: "CR", flag: true },
  { country: "Croatia", code: "385", iso: "HR", flag: true },
  { country: "Cuba", code: "53", iso: "CU", flag: true },
  { country: "Curacao", code: "599", iso: "CW", flag: false },
  { country: "Cyprus", code: "357", iso: "CY", flag: true },
  { country: "Czech Republic", code: "420", iso: "CZ", flag: true },
  {
    country: "Democratic Republic of the Congo",
    code: "243",
    iso: "CD",
    flag: true,
  },
  { country: "Denmark", code: "45", iso: "DK", flag: true },
  { country: "Djibouti", code: "253", iso: "DJ", flag: true },
  { country: "Dominica", code: "1-767", iso: "DM", flag: true },
  {
    country: "Dominican Republic",
    code: "1-809, 1-829, 1-849",
    iso: "DO",
    flag: true,
  },
  { country: "East Timor", code: "670", iso: "TL", flag: true },
  { country: "Ecuador", code: "593", iso: "EC", flag: true },
  { country: "Egypt", code: "20", iso: "EG", flag: true },
  { country: "El Salvador", code: "503", iso: "SV", flag: true },
  { country: "Equatorial Guinea", code: "240", iso: "GQ", flag: true },
  { country: "Eritrea", code: "291", iso: "ER", flag: true },
  { country: "Estonia", code: "372", iso: "EE", flag: true },
  { country: "Ethiopia", code: "251", iso: "ET", flag: true },
  { country: "Falkland Islands", code: "500", iso: "FK", flag: true },
  { country: "Faroe Islands", code: "298", iso: "FO", flag: true },
  { country: "Fiji", code: "679", iso: "FJ", flag: true },
  { country: "Finland", code: "358", iso: "FI", flag: true },
  { country: "France", code: "33", iso: "FR", flag: true },
  { country: "French Polynesia", code: "689", iso: "PF", flag: true },
  { country: "Gabon", code: "241", iso: "GA", flag: true },
  { country: "Gambia", code: "220", iso: "GM", flag: true },
  { country: "Georgia", code: "995", iso: "GE", flag: true },
  { country: "Germany", code: "49", iso: "DE", flag: true },
  { country: "Ghana", code: "233", iso: "GH", flag: true },
  { country: "Gibraltar", code: "350", iso: "GI", flag: true },
  { country: "Greece", code: "30", iso: "GR", flag: true },
  { country: "Greenland", code: "299", iso: "GL", flag: true },
  { country: "Grenada", code: "1-473", iso: "GD", flag: true },
  { country: "Guam", code: "1-671", iso: "GU", flag: true },
  { country: "Guatemala", code: "502", iso: "GT", flag: true },
  { country: "Guernsey", code: "44-1481", iso: "GG", flag: false },
  { country: "Guinea", code: "224", iso: "GN", flag: true },
  { country: "Guinea-Bissau", code: "245", iso: "GW", flag: true },
  { country: "Guyana", code: "592", iso: "GY", flag: true },
  { country: "Haiti", code: "509", iso: "HT", flag: true },
  { country: "Honduras", code: "504", iso: "HN", flag: true },
  { country: "Hong Kong", code: "852", iso: "HK", flag: true },
  { country: "Hungary", code: "36", iso: "HU", flag: true },
  { country: "Iceland", code: "354", iso: "IS", flag: true },
  { country: "India", code: "91", iso: "IN", flag: true },
  { country: "Indonesia", code: "62", iso: "ID", flag: true },
  { country: "Iran", code: "98", iso: "IR", flag: true },
  { country: "Iraq", code: "964", iso: "IQ", flag: true },
  { country: "Ireland", code: "353", iso: "IE", flag: true },
  { country: "Isle of Man", code: "44-1624", iso: "IM", flag: false },
  { country: "Israel", code: "972", iso: "IL", flag: true },
  { country: "Italy", code: "39", iso: "IT", flag: true },
  { country: "Ivory Coast", code: "225", iso: "CI", flag: true },
  { country: "Jamaica", code: "1-876", iso: "JM", flag: true },
  { country: "Japan", code: "81", iso: "JP", flag: true },
  { country: "Jersey", code: "44-1534", iso: "JE", flag: false },
  { country: "Jordan", code: "962", iso: "JO", flag: true },
  { country: "Kazakhstan", code: "7", iso: "KZ", flag: true },
  { country: "Kenya", code: "254", iso: "KE", flag: true },
  { country: "Kiribati", code: "686", iso: "KI", flag: true },
  { country: "Kosovo", code: "383", iso: "XK", flag: false },
  { country: "Kuwait", code: "965", iso: "KW", flag: true },
  { country: "Kyrgyzstan", code: "996", iso: "KG", flag: true },
  { country: "Laos", code: "856", iso: "LA", flag: true },
  { country: "Latvia", code: "371", iso: "LV", flag: true },
  { country: "Lebanon", code: "961", iso: "LB", flag: true },
  { country: "Lesotho", code: "266", iso: "LS", flag: true },
  { country: "Liberia", code: "231", iso: "LR", flag: true },
  { country: "Libya", code: "218", iso: "LY", flag: true },
  { country: "Liechtenstein", code: "423", iso: "LI", flag: true },
  { country: "Lithuania", code: "370", iso: "LT", flag: true },
  { country: "Luxembourg", code: "352", iso: "LU", flag: true },
  { country: "Macao", code: "853", iso: "MO", flag: true },
  { country: "Macedonia", code: "389", iso: "MK", flag: true },
  { country: "Madagascar", code: "261", iso: "MG", flag: true },
  { country: "Malawi", code: "265", iso: "MW", flag: true },
  { country: "Malaysia", code: "60", iso: "MY", flag: true },
  { country: "Maldives", code: "960", iso: "MV", flag: true },
  { country: "Mali", code: "223", iso: "ML", flag: true },
  { country: "Malta", code: "356", iso: "MT", flag: true },
  { country: "Marshall Islands", code: "692", iso: "MH", flag: true },
  { country: "Mauritania", code: "222", iso: "MR", flag: true },
  { country: "Mauritius", code: "230", iso: "MU", flag: true },
  { country: "Mayotte", code: "262", iso: "YT", flag: true },
  { country: "Mexico", code: "52", iso: "MX", flag: true },
  { country: "Micronesia", code: "691", iso: "FM", flag: true },
  { country: "Moldova", code: "373", iso: "MD", flag: true },
  { country: "Monaco", code: "377", iso: "MC", flag: true },
  { country: "Mongolia", code: "976", iso: "MN", flag: true },
  { country: "Montenegro", code: "382", iso: "ME", flag: true },
  { country: "Montserrat", code: "1-664", iso: "MS", flag: true },
  { country: "Morocco", code: "212", iso: "MA", flag: true },
  { country: "Mozambique", code: "258", iso: "MZ", flag: true },
  { country: "Myanmar", code: "95", iso: "MM", flag: true },
  { country: "Namibia", code: "264", iso: "NA", flag: true },
  { country: "Nauru", code: "674", iso: "NR", flag: true },
  { country: "Nepal", code: "977", iso: "NP", flag: true },
  { country: "Netherlands", code: "31", iso: "NL", flag: true },
  { country: "Netherlands Antilles", code: "599", iso: "AN", flag: true },
  { country: "New Caledonia", code: "687", iso: "NC", flag: true },
  { country: "New Zealand", code: "64", iso: "NZ", flag: true },
  { country: "Nicaragua", code: "505", iso: "NI", flag: true },
  { country: "Niger", code: "227", iso: "NE", flag: true },
  { country: "Nigeria", code: "234", iso: "NG", flag: true },
  { country: "Niue", code: "683", iso: "NU", flag: true },
  { country: "North Korea", code: "850", iso: "KP", flag: true },
  { country: "Northern Mariana Islands", code: "1-670", iso: "MP", flag: true },
  { country: "Norway", code: "47", iso: "NO", flag: true },
  { country: "Oman", code: "968", iso: "OM", flag: true },
  { country: "Pakistan", code: "92", iso: "PK", flag: true },
  { country: "Palau", code: "680", iso: "PW", flag: true },
  { country: "Palestine", code: "970", iso: "PS", flag: true },
  { country: "Panama", code: "507", iso: "PA", flag: true },
  { country: "Papua New Guinea", code: "675", iso: "PG", flag: true },
  { country: "Paraguay", code: "595", iso: "PY", flag: true },
  { country: "Peru", code: "51", iso: "PE", flag: true },
  { country: "Philippines", code: "63", iso: "PH", flag: true },
  { country: "Pitcairn", code: "64", iso: "PN", flag: true },
  { country: "Poland", code: "48", iso: "PL", flag: true },
  { country: "Portugal", code: "351", iso: "PT", flag: true },
  { country: "Puerto Rico", code: "1-787, 1-939", iso: "PR", flag: true },
  { country: "Qatar", code: "974", iso: "QA", flag: true },
  { country: "Republic of the Congo", code: "242", iso: "CG", flag: true },
  { country: "Reunion", code: "262", iso: "RE", flag: true },
  { country: "Romania", code: "40", iso: "RO", flag: true },
  { country: "Russia", code: "7", iso: "RU", flag: true },
  { country: "Rwanda", code: "250", iso: "RW", flag: true },
  { country: "Saint Barthelemy", code: "590", iso: "BL", flag: false },
  { country: "Saint Helena", code: "290", iso: "SH", flag: true },
  { country: "Saint Kitts and Nevis", code: "1-869", iso: "KN", flag: true },
  { country: "Saint Lucia", code: "1-758", iso: "LC", flag: true },
  { country: "Saint Martin", code: "590", iso: "MF", flag: false },
  { country: "Saint Pierre and Miquelon", code: "508", iso: "PM", flag: true },
  {
    country: "Saint Vincent and the Grenadines",
    code: "1-784",
    iso: "VC",
    flag: true,
  },
  { country: "Samoa", code: "685", iso: "WS", flag: true },
  { country: "San Marino", code: "378", iso: "SM", flag: true },
  { country: "Sao Tome and Principe", code: "239", iso: "ST", flag: true },
  { country: "Saudi Arabia", code: "966", iso: "SA", flag: true },
  { country: "Senegal", code: "221", iso: "SN", flag: true },
  { country: "Serbia", code: "381", iso: "RS", flag: true },
  { country: "Seychelles", code: "248", iso: "SC", flag: true },
  { country: "Sierra Leone", code: "232", iso: "SL", flag: true },
  { country: "Singapore", code: "65", iso: "SG", flag: true },
  { country: "Sint Maarten", code: "1-721", iso: "SX", flag: false },
  { country: "Slovakia", code: "421", iso: "SK", flag: true },
  { country: "Slovenia", code: "386", iso: "SI", flag: true },
  { country: "Solomon Islands", code: "677", iso: "SB", flag: true },
  { country: "Somalia", code: "252", iso: "SO", flag: true },
  { country: "South Africa", code: "27", iso: "ZA", flag: true },
  { country: "South Korea", code: "82", iso: "KR", flag: true },
  { country: "South Sudan", code: "211", iso: "SS", flag: false },
  { country: "Spain", code: "34", iso: "ES", flag: true },
  { country: "Sri Lanka", code: "94", iso: "LK", flag: true },
  { country: "Sudan", code: "249", iso: "SD", flag: true },
  { country: "Suriname", code: "597", iso: "SR", flag: true },
  { country: "Svalbard and Jan Mayen", code: "47", iso: "SJ", flag: true },
  { country: "Swaziland", code: "268", iso: "SZ", flag: true },
  { country: "Sweden", code: "46", iso: "SE", flag: true },
  { country: "Switzerland", code: "41", iso: "CH", flag: true },
  { country: "Syria", code: "963", iso: "SY", flag: true },
  { country: "Taiwan", code: "886", iso: "TW", flag: true },
  { country: "Tajikistan", code: "992", iso: "TJ", flag: true },
  { country: "Tanzania", code: "255", iso: "TZ", flag: true },
  { country: "Thailand", code: "66", iso: "TH", flag: true },
  { country: "Togo", code: "228", iso: "TG", flag: true },
  { country: "Tokelau", code: "690", iso: "TK", flag: true },
  { country: "Tonga", code: "676", iso: "TO", flag: true },
  { country: "Trinidad and Tobago", code: "1-868", iso: "TT", flag: true },
  { country: "Tunisia", code: "216", iso: "TN", flag: true },
  { country: "Turkey", code: "90", iso: "TR", flag: true },
  { country: "Turkmenistan", code: "993", iso: "TM", flag: true },
  { country: "Turks and Caicos Islands", code: "1-649", iso: "TC", flag: true },
  { country: "Tuvalu", code: "688", iso: "TV", flag: true },
  { country: "U.S. Virgin Islands", code: "1-340", iso: "VI", flag: true },
  { country: "Uganda", code: "256", iso: "UG", flag: true },
  { country: "Ukraine", code: "380", iso: "UA", flag: true },
  { country: "United Arab Emirates", code: "971", iso: "AE", flag: true },
  { country: "United Kingdom", code: "44", iso: "GB", flag: true },
  { country: "United States", code: "1", iso: "US", flag: true },
  { country: "Uruguay", code: "598", iso: "UY", flag: true },
  { country: "Uzbekistan", code: "998", iso: "UZ", flag: true },
  { country: "Vanuatu", code: "678", iso: "VU", flag: true },
  { country: "Vatican", code: "379", iso: "VA", flag: true },
  { country: "Venezuela", code: "58", iso: "VE", flag: true },
  { country: "Vietnam", code: "84", iso: "VN", flag: true },
  { country: "Wallis and Futuna", code: "681", iso: "WF", flag: true },
  { country: "Western Sahara", code: "212", iso: "EH", flag: true },
  { country: "Yemen", code: "967", iso: "YE", flag: true },
  { country: "Zambia", code: "260", iso: "ZM", flag: true },
  { country: "Zimbabwe", code: "263", iso: "ZW", flag: true },
];

export const countriesData = () => {
  return countriesCodes.map((country) => ({
    flag: { name: country.flag ? country.iso.toLocaleLowerCase() : "" },
    key: country.iso.toLocaleLowerCase(),
    value: country.iso.toLocaleLowerCase(),
    text: country.iso.toLocaleLowerCase(),
    phonecode: "+" + country.code,
    name: country.country,
  }));
};

export const getPhoneCodeByCountryCode = (countryCode: string) => {
  const result = countriesCodes.filter(
    (country) => country.iso.toLocaleLowerCase() === countryCode
  );
  return result[0];
};
